
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import GoBackButton from "@/components/reusable/GoBackButton.vue";

export default defineComponent({
  name: "client-invoice-view-child",
  components: { GoBackButton },
  props: ["publicId"],
  data() {
    return { invoiceDetails: {} };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs(" Invoice Information", ["Client Invoices"]);
    });
  },
  created() {
    const router = useRouter();

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Get innvoice data
    this.getClientInvoiceData();

    //Set page title
    document.title =
      "Client Invoice Information | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClientInvoiceData() {
      //Get cart item details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.BROKER_CLIENT_INVOICE_DETAILS_ROUTE}${this.publicId}`
        )
          .then(({ data }) => {
            //Assign data to corresponding fields
            this.invoiceDetails = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            // router.push({ name: "broker-client-invoices-listing" });
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    printPage(el) {
      variables.printPage(el);
    },
  },
});
